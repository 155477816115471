<template>
	<router-link :to="{ name: 'event', params: { id: event.id } }">
		<div class="events-card">
			<div class="image events-card__image shadow">
				<img :src="event.primaryImage.url" :alt="event.title" />
				<div class="upper fill-width upper--one"></div>
				<div class="upper fill-width upper--tow center-item text-center">
					<span> {{ event.content }} </span>
				</div>
			</div>
			<div class="events-card__text">{{ event.title }}</div>
		</div>
	</router-link>
</template>

<script>
export default {
	props: ["event"],
};
</script>

<style scoped lang="scss">
.events-card {
	&__image {
		transition: 200ms;
		border-radius: 20px;
		overflow: hidden;
		position: relative;
		height: 300px;
		cursor: pointer;
		.upper {
			background: #0006;
			color: white;
			transition: 500ms;
			overflow: hidden;
			&--one {
				top: 0;
				bottom: 100%;
			}
			&--tow {
				bottom: 0;
				top: 100%;
				span {
					opacity: 0;
					transition: 200ms;
					transition-delay: 300ms;
				}
			}
		}
		&:hover {
			.upper {
				&--one {
					bottom: 0;
				}
				&--tow {
					top: 0;
					span {
						opacity: 1;
					}
				}
			}
		}
	}
	&__text {
		text-align: center;
		font-size: 1.2rem;
	}
}
</style>