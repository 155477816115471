import * as types from "../mutations"

export default {
    namespaced: true,
    state: {
        filters: []
    },
    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/filters').then(res => {
                commit(types.FILTERS_STORE, res.data)
            })
        }
    },
    mutations: {
        [types.FILTERS_STORE](state, filters) {
            state.filters = filters
        }
    }
}