var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('loader'):_c('div',{staticClass:"container",attrs:{"data-aos":"fade-right","data-aos-anchor-placement":"top-bottom","data-aos-duration":"800"}},[_c('breadcrumb',{attrs:{"links":[
			{ name: _vm.$t('breadcrumbs.home'), path: { name: 'home' } },
			{ name: _vm.$t('breadcrumbs.collection') },
			{
				name: _vm.branch.name,
				path: { name: 'branche', params: { id: _vm.branch.slug } },
			},
			{ name: _vm.categories.find((e) => e.id == Number(_vm.$route.params.id)).name },
		]}}),_c('h1',{staticClass:"title-page"},[_vm._v(_vm._s(_vm.$t("products.title")))]),_c('div',{staticClass:"list"},[_vm._l((_vm.productsUsedFilter),function(filter,i){return _c('div',{key:i,staticClass:"list-item"},[_c('span',{staticClass:"list-item__name me-2",class:{ active: _vm.collectionActive === filter.id },on:{"click":function($event){_vm.collectionActive = _vm.collectionActive === filter.id ? null : filter.id}}},[(filter.image.url)?_c('img',{staticClass:"me-1",attrs:{"src":filter.image.url,"alt":""}}):_vm._e(),_vm._v(" "+_vm._s(filter.name))]),_c('div',{staticClass:"list-item__products",class:{ show: _vm.collectionActive === filter.id }},[_c('products-view',{staticClass:"mobile-products-view",attrs:{"products":_vm.products.filter((e) => e.filterIds.includes(filter.id))}})],1)])}),_c('div',{staticClass:"list-item"},[_c('span',{staticClass:"list-item__name me-2",class:{ active: _vm.collectionActive === -1 },on:{"click":function($event){_vm.collectionActive = _vm.collectionActive === -1 ? null : -1}}},[_c('img',{staticClass:"me-1",attrs:{"src":require("@/assets/Collection page/Menu filters/1223188.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t("bulk")))]),_c('div',{staticClass:"list-item__products",class:{ show: _vm.collectionActive === -1 }},[_c('bulk-view',{staticClass:"mobile-products-view",attrs:{"products":_vm.products.filter((e) => e.isFeatured),"filters":_vm.bulkUsedFilter}})],1)])],2),_c('div',[(_vm.collectionActive != -1)?_c('products-view',{staticClass:"laptop-products-view",attrs:{"products":_vm.productsArr}}):_c('bulk-view',{staticClass:"laptop-products-view",attrs:{"products":_vm.products.filter((e) => e.isFeatured),"filters":_vm.bulkUsedFilter}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }