export default {
    namespaced: true,

    actions: {
        feedback({ commit }, { name, email, body }) {
            return window.axios.post('/feedback', {
                name,
                email,
                body,
                phone: 'phone',
                company: 'company',
                job: 'job',
                country: 'country',
                sector: 'sector',
                standard: 'standard',
                service: 'service',
            });
        },
    },
};
