import * as types from "../mutations"

export default {
    namespaced: true,
    state: {
        products: [],
        bulkProducts: [],
    },
    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/products').then(res => {
                commit(types.PRODUCT_STORE, res.data);
            })
        },
        fetchByFilter({ commit }, { id }) {
            return window.axios.get(`/filters/${id}/products`).then(res => {
                return res.data
            })
        },
        fetchBulk({ commit }) {
            return window.axios.get('/products/getbulk').then(res => {
                commit(types.BULK_PRODUCT_STORE, res.data)
            })
        },
    },
    mutations: {
        [types.PRODUCT_STORE](state, products) {
            state.products = products.filter(e => !e.is_Bulk);
            state.bulkProducts = products.filter(e => e.is_Bulk);
        },
        [types.BULK_PRODUCT_STORE](state, bulkProducts) {
            state.bulkProducts = bulkProducts
        },

    },
    getters: {
        bulkProducts: state => state.products.filter(e => e.is_Bulk)
    }
}