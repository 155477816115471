import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { BASE_URL } from "./constants"
import './helpers'
import VueEasyLightbox from 'vue-easy-lightbox'
import 'aos/dist/aos.css'
import i18n from './lang/index'

Vue.use(VueEasyLightbox)

Vue.config.productionTip = false
window.axios = axios.create({
  baseURL: BASE_URL,
});
window.axios.defaults.headers.common['accept-language'] = i18n.locale;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
