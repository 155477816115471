<template>
	<div
		class="container"
		data-aos="fade-right"
		data-aos-anchor-placement="top-bottom"
		data-aos-duration="800"
	>
		<breadcrumb
			:links="[
				{ name: $t('breadcrumbs.home'), path: { name: 'home' } },
				{ name: $t('breadcrumbs.contact') },
			]"
		></breadcrumb>

		<div class="row">
			<div class="col-lg-7 mb-3">
				<h2 class="title-">{{ $t("contact.fill-form") }}</h2>
				<div class="contact">
					<form
						action=""
						class="form row gy-2 gx-2 justify-content-between"
						@submit.prevent="submit"
					>
						<div class="col-lg-6 col-12">
							<div class="input-fild">
								<input
									type="text"
									:placeholder="$t('contact.form.name')"
									v-model="name"
									ref="name"
									required
								/>
							</div>
						</div>
						<div class="col-lg-6 col-12 input-fild--email">
							<div class="input-fild">
								<input
									type="email"
									:placeholder="$t('contact.form.email')"
									v-model="email"
									ref="email"
									required
								/>
							</div>
						</div>
						<div class="col-lg-6 col-12">
							<div class="input-fild">
								<input
									type="phone"
									:placeholder="$t('contact.form.phone')"
									v-model="phone"
									ref="phone"
									required
								/>
							</div>
						</div>
						<div class="col-lg-6 col-12">
							<div class="input-fild">
								<select name="" id="" v-model="type" ref="type" required>
									<option value="0" selected disabled>
										{{ $t("contact.form.massage-type") }}
									</option>
									<option value="inquiry">
										{{ $t("contact.form.types.inquiry") }}
									</option>
									<option value="complaint">
										{{ $t("contact.form.types.complaint") }}
									</option>
									<option value="suggestions">
										{{ $t("contact.form.types.suggestions") }}
									</option>
								</select>
							</div>
						</div>

						<div class="col-12">
							<div class="input-fild">
								<textarea
									name=""
									id=""
									rows="3"
									:placeholder="$t('contact.form.massage')"
									v-model="body"
									ref="body"
									required
								></textarea>
							</div>
						</div>
						<div class="col-12 final fs-5 m-c" :class="{ show: sent }">
							{{ $t("contact.thank") }}
						</div>
						<div class="col">
							<button
								class="button"
								role="submit"
								:disabled="loadingForem"
								@click="valid"
							>
								{{ loadingForem ? $t("buttons.loading") : $t("buttons.send") }}
							</button>
						</div>
					</form>
				</div>
			</div>
			<div class="col-lg-5 px-lg-5">
				<loader v-if="loading"></loader>
				<div v-else-if="sets.length">
					<h2 class="title-">{{ $t("contact.follow-us") }}</h2>
					<div v-for="set in sets" :key="set.id">
						<h3
							class="sub-title fs-5 mb-1"
							@click="
								stateId === set.id ? (stateId = null) : (stateId = set.id)
							"
							:class="{ show: set.id === stateId }"
						>
							{{ set.name }}
						</h3>
						<div class="states-container" :class="{ show: set.id === stateId }">
							<div
								class="states"
								v-for="categorie in set.branches"
								:key="categorie.id"
							>
								<div class="d-flex social align-items-start mb-1">
									<h4 class="sub-title--state social-title">
										{{ getContacts(categorie)?.name }}
									</h4>
									<div class="social-list d-flex flex-column align-items-start">
										<div
											class="social-item center-item"
											v-if="getContacts(categorie)?.contacts.facebook"
										>
											<a
												:href="getContacts(categorie)?.contacts.facebook"
												target="_blank"
											>
												<i
													class="fab fa-facebook-f"
													style="font-size: 0.75rem"
												></i>
												facebook
											</a>
										</div>
										<div
											class="social-item center-item"
											v-if="getContacts(categorie)?.contacts.whatsapp"
										>
											<a
												:href="`https://wa.me/${
													getContacts(categorie)?.contacts.whatsapp
												}`"
												target="_blank"
											>
												<i
													class="fab fa-whatsapp"
													style="font-size: 0.9rem"
												></i>
												<span dir="ltr">
													{{ getContacts(categorie)?.contacts.whatsapp }}
												</span>
											</a>
										</div>
										<div
											class="social-item center-item"
											v-if="getContacts(categorie)?.contacts.mobile"
										>
											<a
												:href="`tel:${getContacts(categorie)?.contacts.mobile}`"
												target="_blank"
											>
												<i class="fas fa-phone" style="font-size: 0.7rem"></i>
												<span dir="ltr">
													{{ getContacts(categorie)?.contacts.mobile }}
												</span>
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Loader from "@/components/Loader.vue";
import { mapState } from "vuex";

export default {
	components: {
		Breadcrumb,
		Loader,
	},
	data() {
		return {
			name: null,
			email: null,
			phone: null,
			type: "0",
			body: null,

			loading: true,
			loadingForem: false,
			sent: false,

			stateId: null,
		};
	},
	computed: {
		contactsArr() {
			if (!this.stateId) return [];
			const branches = this.branches.find((e) => e.id == this.stateId).branches;
			const branchesIds = branches.map((e) => e.id);
			return this.contacts.filter((e) => branchesIds.includes(e.id));
		},
		...mapState({
			sets: (state) => state.branches.sets,
			categories: (state) => state.branches.categories,
			branches: (state) => state.branches.branches,
		}),
	},
	methods: {
		valid() {
			const validItem = ["name", "email", "type", "phone", "body"];
			var intBool = 0;

			validItem.forEach((e) => {
				this.$refs[e].classList.remove("non-valid");
			});
			validItem.forEach((e) => {
				if (!this.$refs[e].checkValidity()) {
					this.$refs[e].classList.add("non-valid");
					intBool++;
				}
			});
			return !intBool;
		},
		submit() {
			if (!this.valid()) return;
			this.loadingForem = true;
			this.$store
				.dispatch("feedback/feedback", {
					name: this.name,
					email: this.email,
					phone: this.phone,
					body: this.body,
				})
				.finally(() => {
					this.name = null;
					this.email = null;
					this.phone = null;
					this.body = null;

					this.sent = true;
					this.loadingForem = false;
				});
		},
		getContacts(categorie) {
			return this.branches.find((e) => e.slug == categorie.slug);
		},
	},
	watch: {
		"$i18n.locale"() {
			this.loading = true;
			this.$store.dispatch("branches/fetchAllContacts").then(() => {
				this.loading = false;
			});
		},
	},
	mounted() {
		this.$store.dispatch("branches/fetchAllContacts").then(() => {
			this.loading = false;
		});
	},
};
</script>

<style scoped lang="scss">
@import "../../mixins.scss";
.form {
	.input-fild {
		border: 1px solid var(--main-color);
		border-radius: 20px;
		padding: 10px;
		input,
		select,
		textarea {
			width: 100%;
			color: var(--main-color);
		}
	}
}
.states-container {
	max-height: 0;
	transition: 300ms;
	overflow: hidden;
	&.show {
		max-height: 200px;
	}
}
.social {
	&-title {
		flex: 1;
	}
	&-list {
		flex: 3;
	}
	&-item {
		cursor: pointer;
		a {
			padding: 4px;
			i {
				@include marginEnd(5px);
			}
		}
		* {
			font-size: 0.85rem;
			color: var(--main-color);
		}
	}
}

.sub-title {
	color: var(--buttons-color);
	margin-bottom: 7px;
	cursor: pointer;
	&--state {
		font-size: 1rem;
		color: var(--buttons-color);
		margin-bottom: 0.2rem;
		@include marginEnd(0.5rem);
	}
	&::after {
		font-size: 0.9em;
		content: "\f107";
		font-family: "Font Awesome 5 Free";
		font-weight: 900;
	}
	&.show::after {
		content: "\f106";
	}
}
.final {
	transition: 400ms;
	max-height: 0;
	overflow: hidden;
	&.show {
		max-height: 100px;
	}
}
textarea.non-valid {
	color: red !important;
}
input.non-valid {
	border-bottom: 1px solid red !important;
}
button:disabled {
	opacity: 0.75;
	cursor: wait;
}
</style>
