<template>
	<div class="small-product-card d-flex align-items-center">
		<div class="image small-product-card__image me-2">
			<img :src="product.image" alt="" />
		</div>
		<div class="small-product-card__name just-en">{{ product.name }}</div>
		<div class="small-product-card__name just-ar">{{ product.nameAr }}</div>
	</div>
</template>

<script>
export default {
	props: ["product"],
};
</script>

<style scoped lang="scss">
.small-product-card {
	&__image {
		width: 70px;
	}
}
</style>