<template>
	<div class="branch-view">
		<vue-easy-lightbox
			escDisabled
			moveDisabled
			:visible="visible"
			:imgs="branch.images.map((e) => e.url)"
			:index="index"
			@hide="handleHide"
		></vue-easy-lightbox>
		<h2 class="mb-2 branche__title">
			<img src="@/assets/Branches page/mug-hot-alt (1).png" alt="" />
			{{ branch.name }}
		</h2>
		<div class="row">
			<div class="col-lg-5 col-md-12 col-12">
				<div class="contacts mb-5">
					<div class="contacts__item" v-if="branch.contacts.address">
						<div class="contacts__item__logo center-item me-1">
							<img src="@/assets/Branches page/marker.png" alt="" />
						</div>
						<div class="contacts__item__value">
							{{ branch.contacts.address }}
						</div>
					</div>
					<div class="contacts__item" v-if="branch.contacts.mobile">
						<div class="contacts__item__logo center-item me-1">
							<img src="@/assets/Branches page/phone-call.png" alt="" />
						</div>
						<div class="contacts__item__value">
							<a :href="`tel:${branch.contacts.mobile}`" dir="ltr">
								{{ branch.contacts.mobile }}
							</a>
						</div>
					</div>
					<div class="contacts__item" v-if="branch.openHours">
						<div class="contacts__item__logo center-item me-1">
							<img src="@/assets/Branches page/clock-three.png" alt="" />
						</div>
						<div class="contacts__item__value">
							{{ branch.openHours }}
						</div>
					</div>
					<div
						class="d-flex"
						v-if="branch.contacts.facebook || branch.contacts.whatsapp"
					>
						<span>{{ $t("contact.follow") }}</span>
						&nbsp; &nbsp;
						<div class="contacts__item" v-if="branch.contacts.facebook">
							<a :href="branch.contacts.facebook" target="_blank">
								<div class="contacts__item__logo invert center-item me-1">
									<i class="fab fa-facebook-f"></i>
								</div>
							</a>
						</div>
						<div class="contacts__item" v-if="branch.contacts.whatsapp">
							<a
								:href="`https://wa.me/${branch.contacts.whatsapp}`"
								target="_blank"
							>
								<div class="contacts__item__logo invert center-item me-1">
									<i class="fab fa-whatsapp"></i>
								</div>
							</a>
						</div>
					</div>
				</div>
				<div class="gallery mb-5" v-if="branch.images.length">
					<div class="gallery-title mb-2">{{ $t("branches.about") }}</div>
					<div class="gallery__grid">
						<div
							class="gallery__grid__item image image--cover"
							v-for="(image, i) in branch.images"
							:key="i"
							@click="showMultiple(i)"
						>
							<img :src="image.url" alt="" />
							<div
								class="img-overflow fill-width fill-height center-item"
								v-if="(branch.images.length > 5) & (i === 4)"
							>
								+{{ branch.images.length - 5 }}
							</div>
						</div>
					</div>
				</div>
				<router-link
					:to="{
						name: 'all-products',
						params: { id: getIdfromSlug(branch.slug) },
					}"
				>
					<div class="button button--sub">{{ $t("buttons.our-menu") }}</div>
				</router-link>
			</div>
			<div class="col-lg-7 col-md-12 col-12">
				<iframe
					v-if="branch.contacts.location"
					:src="branch.contacts.location"
					allowfullscreen=""
					loading="lazy"
					referrerpolicy="no-referrer-when-downgrade"
					class="map"
				></iframe>
			</div>
		</div>
	</div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";
import { mapState } from "vuex";

export default {
	props: ["branch"],
	components: {
		VueEasyLightbox,
	},
	data() {
		return {
			visible: false,
			index: 0,
			// img: this.branch.images,
		};
	},
	computed: {
		...mapState({
			categories: (state) => state.branches.categories,
		}),
	},
	methods: {
		showMultiple(i) {
			this.index = i;
			this.show();
		},
		show() {
			this.visible = true;
		},
		handleHide() {
			this.visible = false;
		},
		getIdfromSlug(slug) {
			return this.categories.find((e) => e.slug == slug).id;
		},
	},
};
</script>

<style scoped lang="scss">
.branch-view {
	.branche__title {
		font-size: 2rem;
		color: var(--third-color);
		img {
			height: 2rem;
			margin-bottom: -0.1rem;
			vertical-align: baseline;
		}
	}
}
.contacts {
	&__item {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		&__logo {
			min-width: 2rem;
			max-width: 2rem;
			height: 2rem;
			border-radius: 50%;
			background: var(--third-color);
			img {
				width: 60%;
				object-fit: contain;
			}
			i {
				color: white;
			}
			&.invert {
				background: transparent;
				border: 2px solid var(--third-color);
				@media (max-width: 992px) {
					border: 1px solid var(--third-color);
				}
				i {
					color: var(--third-color);
				}
			}
		}
	}
}
.gallery {
	&-title {
		font-weight: bold;
		font-size: 1.2rem;
	}
	&__grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		width: 100%;
		@media (max-width: 576px) {
			grid-template-columns: repeat(2, 1fr);
		}
		gap: 10px;
		&__item {
			object-fit: cover;
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0 0 1rem #0001;
			position: relative;
			height: 150px;
			@media (max-width: 768px) {
				height: 120px;
			}
			@media (max-width: 576px) {
				height: 40vw;
			}
			.img-overflow {
				background: #0009;
				color: white;
				font-size: 2rem;
				font-weight: bold;
			}
		}
	}
}
.map {
	width: 100%;
	height: 100%;
	min-height: 400px;
	border: 1px solid #ccc;
	border-radius: 40px;
	@media (max-width: 992px) {
		margin-top: 2rem;
	}
	@media (max-width: 576px) {
		min-height: 90vw;
	}
}
</style>