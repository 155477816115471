<template>
	<loader v-if="loading"></loader>
	<div
		v-else
		class="container"
		data-aos="fade-right"
		data-aos-anchor-placement="top-bottom"
		data-aos-duration="800"
	>
		<breadcrumb
			:links="[
				{ name: $t('breadcrumbs.home'), path: { name: 'home' } },
				{ name: $t('breadcrumbs.collection') },
				{
					name: branch.name,
					path: { name: 'branche', params: { id: branch.slug } },
				},
				{ name: categories.find((e) => e.id == Number($route.params.id)).name },
			]"
		></breadcrumb>
		<h1 class="title-page">{{ $t("products.title") }}</h1>
		<div class="list">
			<div class="list-item" v-for="(filter, i) in productsUsedFilter" :key="i">
				<span
					class="list-item__name me-2"
					@click="
						collectionActive = collectionActive === filter.id ? null : filter.id
					"
					:class="{ active: collectionActive === filter.id }"
				>
					<img
						class="me-1"
						:src="filter.image.url"
						alt=""
						v-if="filter.image.url"
					/>
					{{ filter.name }}</span
				>
				<div
					class="list-item__products"
					:class="{ show: collectionActive === filter.id }"
				>
					<products-view
						class="mobile-products-view"
						:products="products.filter((e) => e.filterIds.includes(filter.id))"
					></products-view>
				</div>
			</div>
			<div class="list-item">
				<span
					class="list-item__name me-2"
					@click="collectionActive = collectionActive === -1 ? null : -1"
					:class="{ active: collectionActive === -1 }"
					><img
						class="me-1"
						src="@/assets/Collection page/Menu filters/1223188.png"
						alt=""
					/>
					{{ $t("bulk") }}</span
				>
				<div
					class="list-item__products"
					:class="{ show: collectionActive === -1 }"
				>
					<bulk-view
						class="mobile-products-view"
						:products="products.filter((e) => e.isFeatured)"
						:filters="bulkUsedFilter"
					></bulk-view>
				</div>
			</div>
		</div>
		<div>
			<products-view
				v-if="collectionActive != -1"
				class="laptop-products-view"
				:products="productsArr"
			></products-view>
			<bulk-view
				v-else
				class="laptop-products-view"
				:products="products.filter((e) => e.isFeatured)"
				:filters="bulkUsedFilter"
			></bulk-view>
		</div>
	</div>
</template>

<script>
import ProductsView from "@/components/ProductsView.vue";
import BulkView from "@/components/BulkView.vue";
import Loader from "@/components/Loader.vue";
import { mapState } from "vuex";
import Breadcrumb from "@/components/Breadcrumb.vue";

export default {
	components: {
		ProductsView,
		Loader,
		Breadcrumb,
		BulkView,
	},
	data() {
		return {
			loading: true,
			collectionActive: 0,
			products: [],
			productFilterIds: new Set(),
			bulkFilterIds: new Set(),

			forceCompute: 0,
		};
	},
	computed: {
		branch() {
			const categoriesId = this.categories.find(
				(e) => e.id == Number(this.$route.params.id)
			).setsIds[0];
			return this.sets.find((e) => e.id == categoriesId);
		},
		productsArr() {
			return this.products.filter((e) =>
				e.filterIds.includes(this.collectionActive)
			);
		},
		productsUsedFilter() {
			this.forceCompute;
			return this.filters.filter((e) => this.productFilterIds.has(e.id));
		},
		bulkUsedFilter() {
			this.forceCompute;
			return this.filters.filter((e) => this.bulkFilterIds.has(e.id));
		},
		...mapState({
			sets: (state) => state.branches.sets,
			categories: (state) => state.branches.categories,
			branches: (state) => state.branches.branches,
			filters: (state) => state.filters.filters,

			productsAll: (state) => state.products.products,
		}),
	},
	methods: {
		fetch() {
			const categoriesSlug = this.categories.find(
				(e) => e.id == this.$route.params.id
			).slug;
			const monyCode = this.branches.find(
				(c) => categoriesSlug == c.slug
			).moneyCode;
			monyCode
				? (document.querySelector(
						"body"
				  ).style.cssText = `--money-code: '${monyCode}'`)
				: (document.querySelector("body").style.cssText = `--money-code: ''`);
			this.collectionActive = this.$route.query.filter
				? Number(this.$route.query.filter)
				: this.filters[0].id;

			// this.$store.dispatch("products/fetchByCategories", {
			// 	id: this.$route.params.id,
			// });
			this.products = this.productsAll.filter((e) => {
				return e.categoriesIds.includes(Number(this.$route.params.id));
			});
			this.bulkFilterIds.clear();
			this.productFilterIds.clear();
			this.products.forEach((p) => {
				p.filterIds.forEach((f) => {
					p.isFeatured
						? this.bulkFilterIds.add(f)
						: this.productFilterIds.add(f);
				});
			});
		},
	},
	watch: {
		"$route.params.id"() {
			this.fetch();
			this.forceCompute++;
		},
		"$route.query.filter"() {
			this.collectionActive = this.$route.query.filter;
			this.forceCompute++;
		},
	},
	mounted() {
		this.fetch();
		setTimeout(() => {
			this.loading = false;
		}, 100);
	},
};
</script>

<style scoped lang="scss">
@import "../../mixins.scss";
$list-item-color: #555;
.list {
	display: flex;
	margin-bottom: 2rem;
	&-item {
		&__name {
			display: block;
			color: $list-item-color;
			margin-bottom: 0.7rem;
			transition: 200ms;
		}
	}
	@media (min-width: 768px) {
		flex-wrap: wrap;
		&-item__name {
			cursor: pointer;
			padding: 0.3em 1em;
			border: 1px solid $list-item-color;
			border-radius: 2em;
			img {
				height: 1rem;
				@media (min-width: 768px) {
					margin: 0 !important;
				}
			}
			&.active {
				// color: var(--sec-color);
				border: 1px solid #0006;
				box-shadow: 0 0 10px #0006;
				background: #ddd;
			}
		}
	}
	@media (max-width: 768px) {
		flex-direction: column;
		align-items: center;
		&-item {
			width: 100%;
			&__name {
				font-size: 2rem;
				display: flex;
				align-items: center;
				padding: 0.5rem;
				border-radius: 10px;
				&::after {
					content: "\f107";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					@include marginStart(auto);
				}
				img {
					height: 2rem;
				}
				&.active {
					box-shadow: 0 0 20px #0003;
				}
			}
			&__products {
				max-height: 0;
				overflow: hidden;
				transition: 300ms;
				&.show {
					max-height: 1000px;
				}
			}
		}
	}
}
@media (min-width: 768px) {
	.mobile-products-view {
		display: none;
	}
}
@media (max-width: 768px) {
	.laptop-products-view {
		display: none;
	}
}
</style>
<style>
.price::after {
	content: " " var(--money-code);
}
</style>