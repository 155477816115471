import { render, staticRenderFns } from "./EventsCard.vue?vue&type=template&id=c9dc1b64&scoped=true&"
import script from "./EventsCard.vue?vue&type=script&lang=js&"
export * from "./EventsCard.vue?vue&type=script&lang=js&"
import style0 from "./EventsCard.vue?vue&type=style&index=0&id=c9dc1b64&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9dc1b64",
  null
  
)

export default component.exports