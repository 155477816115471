export const PRODUCT_STORE = "PRODUCT_STORE"
export const BULK_PRODUCT_STORE = "BULK_PRODUCT_STORE"

export const JOURNEY_STORE = "JOURNEY_STORE"

export const FILTERS_STORE = "FILTERS_STORE"

export const BRANCHES_STORE = "BRANCHES_STORE"
export const SETS_STORE = "SETS_STORE"
export const CATEGORIES_STORE = "CATEGORIES_STORE"

export const CONTACTS_STORE = "CONTACTS_STORE"

export const STATES_STORE = "STATES_STORE"

export const SLIDER_STORE = "SLIDER_STORE"

export const ABOUT_STORE = "ABOUT_STORE"
export const VALUES_STORE = "VALUES_STORE"

export const EVENTS_STORE = "EVENTS_STORE"