import Home from "../views/Home.vue"
import AllProducts from "../views/Products/AllProduct.vue"
import Branches from "../views/Branches/Branches.vue"
import AllEvents from "../views/Events/AllEvents.vue"
import EventView from "../views/Events/EventView.vue"
import Contact from "../views/Contact/Contact.vue"
import About from "../views/About/About.vue"

export default [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/all-products/:id',
        name: 'all-products',
        component: AllProducts
    },
    {
        path: '/branch/:id',
        name: 'branche',
        component: Branches
    },
    {
        path: '/all-events',
        name: 'all-events',
        component: AllEvents
    },
    {
        path: '/event/:id',
        name: 'event',
        component: EventView
    },
    {
        path: '/contact-us',
        name: 'contact',
        component: Contact
    },
    {
        path: '/about',
        name: 'about',
        component: About
    },

]