var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_vm._m(0),_c('div',{staticClass:"bottom mb-5"},[_c('div',{staticClass:"top"},[_vm._m(1),_c('div',{staticClass:"top-description",domProps:{"innerHTML":_vm._s(_vm.about?.value.split('.')[0])}})]),_c('div',{staticClass:"links"},[_c('ul',[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('li',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t("footer.home")))])]),_c('router-link',{attrs:{"to":{ name: 'all-events' }}},[_c('li',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t("footer.albums")))])]),_c('router-link',{attrs:{"to":{ name: 'about' }}},[_c('li',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t("footer.about")))])]),_c('li',{staticClass:"cp",on:{"click":_vm.seeBranches}},[_vm._v(_vm._s(_vm.$t("footer.branches")))]),_c('li',{staticClass:"cp",on:{"click":_vm.seeCollections}},[_vm._v(" "+_vm._s(_vm.$t("footer.collections"))+" ")]),_c('a',{attrs:{"href":"https://mirta.net/","target":"mirta_co"}},[_c('li',[_vm._v(" "+_vm._s(_vm.$t("footer.mirta"))+" ")])])],1)]),_c('div',{staticClass:"follow-us"},[_c('ul',{staticClass:"d-flex mb-1"},[_c('div',{staticClass:"footer__title mb-1 me-2 fw-bold"},[_vm._v(_vm._s(_vm.$t("eygpet")))]),_vm._m(2),_vm._m(3)]),_c('ul',{staticClass:"d-flex"},[_c('div',{staticClass:"footer__title me-2 fw-bold"},[_vm._v(_vm._s(_vm.$t("uae")))]),_vm._m(4),_vm._m(5),_vm._m(6)])]),_c('div',{staticClass:"massage-us"},[_c('div',{staticClass:"footer__title mb-3 fw-bold"},[_vm._v(" "+_vm._s(_vm.$t("footer.message-us"))+" ")]),_c('div',{staticClass:"email"},[_vm._v("info@mirta.net")])])]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image footer__image fill-width fill-height"},[_c('img',{attrs:{"src":require("@/assets/Homepage/footer.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image mb-2"},[_c('img',{attrs:{"src":require("@/assets/Homepage/logo.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"follow-us-items center-item me-1"},[_c('i',{staticClass:"fab fa-facebook-f"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"follow-us-items center-item me-1"},[_c('i',{staticClass:"fab fa-whatsapp"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"follow-us-items center-item me-1"},[_c('i',{staticClass:"fab fa-facebook-f"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"follow-us-items center-item me-1"},[_c('i',{staticClass:"fab fa-whatsapp"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"follow-us-items center-item me-1"},[_c('i',{staticClass:"fab fa-instagram"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyrights d-flex justify-content-between"},[_c('span',[_vm._v("CopyRights © All Rights Reserved")]),_c('a',{staticClass:"beetronix",attrs:{"href":"http://beetronix.com/"}},[_vm._v(" BEETRONIX ")])])
}]

export { render, staticRenderFns }