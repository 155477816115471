<template>
	<div
		v-if="!products.length"
		class="text-center fs-3 text-uppercase fw-light text-muted my-3"
	>
		{{ $t("infos.no-products") }}
	</div>
	<div class="bulk-grid" v-else>
		<div
			class="bulk-type"
			v-for="filter in filters.filter((e) => e.slug != 'others')"
			:key="filter.id"
		>
			<h4 class="bulk-title">{{ filter.name }}</h4>
			<div class="bulk-contant mb-4">
				<div
					class="bulk-item"
					v-for="product in products.filter((e) =>
						e.filterIds.includes(filter.id)
					)"
					:key="product.id"
				>
					<span>
						{{
							product.name.length > maxLetter
								? product.name.slice(0, maxLetter) + "..."
								: product.name
						}}
					</span>
					<span class="price">
						{{ product.price }}
					</span>
				</div>
			</div>
		</div>
		<div class="bulk-type">
			<h4
				class="bulk-title"
				v-for="product in products.filter((e) =>
					e.filterIds.find((c) => c == filterOtherId)
				)"
				:key="product.id"
			>
				{{ product.name }}
			</h4>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
export default {
	props: ["products", "filters"],
	computed: {
		maxLetter() {
			return window.innerWidth > 992 ? 35 : 26;
		},
		filterOtherId() {
			return this.filtersAll.find((e) => e.slug == "others")?.id;
		},
		...mapState({
			filtersAll: (state) => state.filters.filters,
		}),
		// bulkProducts() {
		// 	return this.sortedBulkProducts.find(
		// 		(e) => e.stateId === Number(this.$route.params.id)
		// 	);
		// },
		// ...mapState({
		// 	filtersAll: (state) => state.filters.filters,
		// }),
	},
};
</script>

<style scoped lang="scss">
@import "../mixins.scss";
.bulk-title {
	font-size: 1.3rem;
	color: var(--main-color);
	margin-bottom: 0.5rem;
	text-transform: capitalize;
}
.bulk-contant {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.bulk-item {
	display: flex;
	justify-content: space-between;
	width: 50%;
	@include paddingEnd(8rem);
	@media (max-width: 1200px) {
		@include paddingEnd(0);
		&:nth-child(odd) {
			@include paddingEnd(2rem);
		}
		&:nth-child(even) {
			@include paddingStart(2rem);
		}
	}
	@media (max-width: 768px) {
		width: 100%;
		@include paddingEnd(0 !important);
		@include paddingStart(0 !important);
	}
	.price {
		color: var(--third-color);
	}
}
</style>