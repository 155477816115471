var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"nav",staticClass:"nav-container"},[_c('nav',{staticClass:"nav",staticStyle:{"--nav-color":"white","--nav-bg-color":"var(--main-color)"}},[_c('div',{staticClass:"brand",staticStyle:{"--nav-brand-width":"70px"}},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('div',{staticClass:"image brand__image"},[_c('img',{attrs:{"src":require("@/assets/Homepage/logo.png"),"alt":"logo"}}),_c('div',{staticClass:"image brand__image--overflow fix-posetion-left"},[_c('img',{attrs:{"src":require("@/assets/Homepage/year.png"),"alt":"year"}})])])])],1),_c('a',{staticClass:"other-site d-flex align-items-center",attrs:{"href":"https://mirta.net/","target":"mirta_co"}},[_vm._m(0),_vm._v(" "+_vm._s(_vm.$t("other-site"))+" ")]),_vm._m(1),_c('div',{ref:"links",staticClass:"links collapse",attrs:{"id":"links"}},[_c('ul',{staticClass:"links__list"},[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_c('li',{staticClass:"links__list__item"},[_vm._v(_vm._s(_vm.$t("navbar.home")))])]),_c('li',{ref:"branches",staticClass:"links__list__item",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;_vm.collectionListOpen = false;
						_vm.branchesListOpen = !_vm.branchesListOpen;}}},[_c('span',{staticClass:"has-dropdown-list",class:{ active: _vm.branchesListOpen },attrs:{"no-hover":""},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;_vm.collectionListOpen = false;
							_vm.branchesListOpen = !_vm.branchesListOpen;}}},[_vm._v(" "+_vm._s(_vm.$t("navbar.branches"))+" ")]),_c('div',{staticClass:"dropdown-list shadow",class:{ show: _vm.branchesListOpen },attrs:{"id":"branches-list"}},[_c('ul',{staticClass:"dropdown-list--list p-3"},_vm._l((_vm.sets),function(branche,i){return _c('router-link',{key:i,attrs:{"to":{ name: 'branche', params: { id: branche.slug } }}},[_c('li',{staticClass:"dropdown-list--item m-1"},[_vm._v(" "+_vm._s(branche.name)+" ")])])}),1)])]),_c('li',{ref:"collections",staticClass:"links__list__item",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;_vm.branchesListOpen = false;
						_vm.collectionListOpen = !_vm.collectionListOpen;}}},[_c('span',{staticClass:"has-dropdown-list",class:{ active: _vm.collectionListOpen },attrs:{"no-hover":""},on:{"click":function($event){if($event.target !== $event.currentTarget)return null;_vm.branchesListOpen = false;
							_vm.collectionListOpen = !_vm.collectionListOpen;}}},[_vm._v(" "+_vm._s(_vm.$t("navbar.our-collection"))+" ")]),_c('div',{staticClass:"dropdown-list shadow",class:{ show: _vm.collectionListOpen, 'show-filters': _vm.openFilters },attrs:{"id":"collection-list"}},[(_vm.loading)?_c('loader'):_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"cities mb-3"},[_vm._l((_vm.sets),function(branche){return _c('div',{key:branche.id,staticClass:"cities-item me-2",class:{ active: branche.id === _vm.activeBranche },on:{"click":function($event){_vm.activeBranche = branche.id;
										_vm.activeState = branche.branches[0].id;
										_vm.openFilters = false;}}},[_vm._v(" "+_vm._s(branche.name)+" ")])}),_c('div',{staticClass:"state-close for-mobile",on:{"click":function($event){_vm.openFilters = false}}},[_c('i',{staticClass:"fas fa-times touch"})])],2),_c('div',{staticClass:"list-body"},[_c('div',{staticClass:"city-branches me-1 col-4"},_vm._l((_vm.sets.find((e) => e.id == _vm.activeBranche)
											?.branches),function(state){return _c('div',{key:state.id,staticClass:"city-branches-item",class:{ active: state.id === _vm.activeState },on:{"click":function($event){_vm.activeState = state.id;
											_vm.openFilters = true;}}},[_vm._v(" "+_vm._s(state.name)+" ")])}),0),_c('div',{staticClass:"col-8 branche-collection"},[_c('div',{staticClass:"px-3 py-1"},[_vm._l((_vm.filtersByState),function(filter,i){return _c('div',{key:i,staticClass:"branche-collection-item"},[_c('router-link',{attrs:{"to":{
													name: 'all-products',
													params: {
														id: _vm.activeState,
													},
													query: { filter: filter.id },
												}}},[_vm._v(" "+_vm._s(filter.name)+" ")])],1)}),_c('div',{staticClass:"branche-collection-item"},[_c('router-link',{attrs:{"to":{
													name: 'all-products',
													params: {
														id: _vm.activeState,
													},
													query: { filter: -1 },
												}}},[_vm._v(" "+_vm._s(_vm.$t("bulk"))+" ")])],1)],2)])])])],1)]),_c('router-link',{attrs:{"to":{ name: 'all-events' }}},[_c('li',{staticClass:"links__list__item"},[_vm._v(_vm._s(_vm.$t("navbar.albums")))])]),_c('router-link',{attrs:{"to":{ name: 'about' }}},[_c('li',{staticClass:"links__list__item"},[_vm._v(_vm._s(_vm.$t("navbar.about")))])]),_c('router-link',{attrs:{"to":{ name: 'contact' }}},[_c('li',{staticClass:"links__list__item border"},[_vm._v(" "+_vm._s(_vm.$t("navbar.contact-us"))+" ")])]),_c('li',{ref:"lang",staticClass:"links__list__item",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;_vm.langListOpen = !_vm.langListOpen}}},[_c('i',{staticClass:"fa fa-globe",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;_vm.langListOpen = !_vm.langListOpen}}}),_c('div',{staticClass:"dropdown-list shadow",class:{ show: _vm.langListOpen },attrs:{"id":"lang-list"}},[_c('ul',{staticClass:"dropdown-list--list p-3"},[_c('li',{staticClass:"dropdown-list--item m-1",on:{"click":function($event){return _vm.changeLang('en')}}},[_vm._v(" English ")]),_c('li',{staticClass:"dropdown-list--item m-1",on:{"click":function($event){return _vm.changeLang('ar')}}},[_vm._v(" عربي ")])])])])],1)])]),_c('header',{staticClass:"header",on:{"click":function($event){_vm.branchesListOpen = false;
			_vm.collectionListOpen = false;}}},[_c('div',{ref:"header_image",staticClass:"image header__image fill-width fill-height"},[_c('img',{key:_vm.sliderImageIndex,attrs:{"src":_vm.slider[_vm.sliderImageIndex % _vm.slider.length]?.image,"alt":""}}),_vm._m(2)]),_c('div',{staticClass:"slider"},[_c('hooper',{ref:"carousel",staticClass:"hooper",attrs:{"infiniteScroll":true,"mouseDrag":false,"wheelControl":false,"autoPlay":true,"playSpeed":4000,"rtl":!_vm.isEnglish},on:{"slide":_vm.changeSliderImage}},_vm._l((_vm.slider),function(slide){return _c('slide',{key:slide.id},[_c('h1',{staticClass:"slider-header-item",domProps:{"innerHTML":_vm._s(slide.description)}})])}),1)],1)]),_vm._m(3)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"image"},[_c('img',{attrs:{"src":require("@/assets/boxes.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hamburger"},[_c('i',{staticClass:"fa fa-bars px-4 py-3",attrs:{"data-bs-toggle":"collapse","data-bs-target":"#links"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-clip-image"},[_c('img',{attrs:{"src":require("@/assets/Homepage/clip.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-image-overflow image"},[_c('img',{staticStyle:{"filter":"brightness(99%)"},attrs:{"src":require("../assets/Homepage/clip.png"),"alt":""}})])
}]

export { render, staticRenderFns }