<template>
	<div class="products-card">
		<div class="image">
			<hooper
				class="hooper"
				:infiniteScroll="true"
				:mouseDrag="false"
				:wheelControl="false"
				:autoPlay="true"
				:playSpeed="4000"
				dir="ltr"
				v-if="product.images.length > 1"
			>
				<slide v-for="image in product.images" :key="image.id">
					<img :src="image.url" alt="product image" />
				</slide>
				<hooper-navigation slot="hooper-addons"></hooper-navigation>
			</hooper>

			<img :src="product.primaryImage.url" alt="product image" v-else />
		</div>
		<div class="px-md-2 text-md-center">
			<div class="name">{{ product.name }}</div>
			<div class="price">{{ product.price }}</div>
		</div>
	</div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
	props: ["product"],
	components: {
		Hooper,
		Slide,
		HooperNavigation,
	},
};
</script>

<style scoped lang="scss">
@import "../mixins.scss";
.products-card {
	.image {
		width: 100%;
		height: 8rem;
		&_container {
			overflow: hidden;
		}
		.hooper {
			height: 100%;
		}
	}
	.name {
		font-size: 1.3rem;
	}
	.price {
		color: var(--third-color);
	}
	@media (max-width: 768px) {
		display: flex;
		align-items: center;
		.image {
			width: 100px;
			height: 100px;
		}
	}
}
</style>
<style>
.hooper-prev {
	left: -30px;
}
.hooper-next {
	right: -30px;
}
@media (max-width: 768px) {
	.hooper-navigation {
		display: none;
	}
}
</style>