<template>
	<loader v-if="loading"></loader>
	<div
		class="container"
		v-else
		data-aos="fade-right"
		data-aos-anchor-placement="top-bottom"
		data-aos-duration="800"
		ref="container"
	>
		<breadcrumb
			:links="[
				{ name: $t('breadcrumbs.home'), path: { name: 'home' } },
				{ name: $t('breadcrumbs.branches') },
				{ name: branchName },
			]"
		></breadcrumb>
		<h1 class="title-page">{{ $t("branches.title") }}</h1>
		<branche-view
			class="branche"
			v-for="(branch, i) in branchesArr"
			:key="i"
			:branch="branch"
		></branche-view>
	</div>
</template>

<script>
import BrancheView from "@/components/BrancheView.vue";
import Loader from "@/components/Loader.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { mapGetters, mapState } from "vuex";

export default {
	data() {
		return {
			loading: false,
			branchesSlugs: [],
			branchesArr: [],
		};
	},
	components: {
		BrancheView,
		Loader,
		Breadcrumb,
	},
	methods: {
		fetch() {
			this.loading = false;
			// this.sets
			// 	.find((e) => e.slug === this.$route.params.id)
			// 	.branches.forEach((e) => {
			// 		this.branchesArrSlugs.push(
			// 			this.$store
			// 				.dispatch("branches/fetchContactsBySlug", { slug: e.slug })
			// 				.then((res) => {
			// 					this.branchesArr.push(res);
			// 				})
			// 		);
			// 	});
			// 	Promise.all(this.branchesArrSlugs).finally(() => {
			// 		this.loading = false;
			// 	});

			this.branchesSlugs = this.sets
				.find((e) => e.slug == this.$route.params.id)
				.branches.map((e) => e.slug);

			this.branchesArr = this.branches.filter((e) =>
				this.branchesSlugs.includes(e.slug)
			);
		},
	},
	watch: {
		"$route.params"() {
			this.fetch();
			setTimeout(() => {
				this.$refs.container.style.transform = "unset";
			}, 1000);
		},
	},
	computed: {
		branchName() {
			return this.sets.find((e) => e.slug === this.$route.params.id).name;
		},
		...mapGetters({
			branche: "branches/getById",
		}),
		...mapState({
			sets: (state) => state.branches.sets,
			branches: (state) => state.branches.branches,
		}),
	},
	mounted() {
		this.fetch();
		setTimeout(() => {
			this.$refs.container.style.transform = "unset";
		}, 1000);
	},
};
</script>

<style scoped lang="scss">
.branche {
	margin-bottom: 4rem;
}
</style>