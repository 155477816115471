import * as types from "../mutations"

export default {
    namespaced: true,

    state: {
        events: []
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/albums').then(res => {
                commit(types.EVENTS_STORE, res.data)
                return res.data
            })
        },
        fetchById({ commit }, { id }) {
            return window.axios.get(`/albums/${id}`).then(res => {
                return res.data
            })
        }
    },

    mutations: {
        [types.EVENTS_STORE](state, events) {
            state.events = events
        }
    }
}