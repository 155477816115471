<template>
	<footer class="footer">
		<div class="image footer__image fill-width fill-height">
			<img src="@/assets/Homepage/footer.png" alt="" />
		</div>
		<div class="bottom mb-5">
			<div class="top">
				<div class="image mb-2">
					<img src="@/assets/Homepage/logo.png" alt="" />
				</div>
				<div class="top-description" v-html="about?.value.split('.')[0]"></div>
			</div>
			<div class="links">
				<ul>
					<router-link :to="{ name: 'home' }">
						<li class="mb-3">{{ $t("footer.home") }}</li>
					</router-link>
					<router-link :to="{ name: 'all-events' }">
						<li class="mb-3">{{ $t("footer.albums") }}</li>
					</router-link>
					<router-link :to="{ name: 'about' }">
						<li class="mb-3">{{ $t("footer.about") }}</li>
					</router-link>
					<li @click="seeBranches" class="cp">{{ $t("footer.branches") }}</li>
					<li @click="seeCollections" class="cp">
						{{ $t("footer.collections") }}
					</li>
					<a href="https://mirta.net/" target="mirta_co">
						<li>
							{{ $t("footer.mirta") }}
						</li>
					</a>
				</ul>
			</div>
			<div class="follow-us">
				<!-- <div class="footer__title mb-1 fw-bold">Follow US</div> -->
				<ul class="d-flex mb-1">
					<div class="footer__title mb-1 me-2 fw-bold">{{ $t("eygpet") }}</div>
					<li class="follow-us-items center-item me-1">
						<i class="fab fa-facebook-f"></i>
					</li>
					<li class="follow-us-items center-item me-1">
						<i class="fab fa-whatsapp"></i>
					</li>
				</ul>
				<ul class="d-flex">
					<div class="footer__title me-2 fw-bold">{{ $t("uae") }}</div>
					<li class="follow-us-items center-item me-1">
						<i class="fab fa-facebook-f"></i>
					</li>
					<li class="follow-us-items center-item me-1">
						<i class="fab fa-whatsapp"></i>
					</li>
					<li class="follow-us-items center-item me-1">
						<i class="fab fa-instagram"></i>
					</li>
				</ul>
			</div>
			<div class="massage-us">
				<div class="footer__title mb-3 fw-bold">
					{{ $t("footer.message-us") }}
				</div>
				<div class="email">info@mirta.net</div>
			</div>
		</div>
		<div class="copyrights d-flex justify-content-between">
			<span>CopyRights &copy; All Rights Reserved</span>
			<a href="http://beetronix.com/" class="beetronix"> BEETRONIX </a>
		</div>
	</footer>
</template>

<script>
import { openBranches, openCollection } from "../variables";
import { mapGetters } from "vuex";
export default {
	data() {
		return {
			openBranches,
			openCollection,
		};
	},
	computed: {
		...mapGetters({
			about: "about/about",
		}),
	},
	methods: {
		seeCollections() {
			setTimeout(() => {
				window.scrollTo({ top: 0, behavior: "smooth" });
				this.openCollection.open = !this.openCollection.open;
			}, 100);
		},
		seeBranches() {
			setTimeout(() => {
				window.scrollTo({ top: 0, behavior: "smooth" });
				this.openBranches.open = !this.openBranches.open;
			}, 100);
		},
	},
};
</script>

<style scoped lang="scss">
@import "../mixins.scss";
$transparent-offset: 50%;
$transparent-offset-small: 30%;

.footer {
	* {
		color: white;
	}

	padding: 3rem var(--window-padding) 0;
	position: relative;
	z-index: 1;

	@media (max-width: 992px) {
		padding-top: 4.5rem;
		background-repeat: no-repeat;
		background-image: linear-gradient(
			to bottom,
			transparent $transparent-offset,
			var(--forth-color) $transparent-offset
		);
	}
	@media (max-width: 576px) {
		background-image: linear-gradient(
			to bottom,
			transparent $transparent-offset-small,
			var(--forth-color) $transparent-offset-small
		);
	}

	@media (max-width: 992px) {
		background-size: auto 100%;
		background-position: 20%;
	}

	.bottom {
		display: flex;
		align-items: flex-end;

		@media (max-width: 992px) {
			flex-direction: column;
			align-items: flex-start;

			> div {
				margin-bottom: 2.5rem;
			}
		}
		.top {
			flex: 3;
			@include marginEnd(5rem);
			@media (max-width: 576px) {
				@include marginEnd(0rem);
			}
			.image {
				width: 100px;
				filter: brightness(100);
			}

			&-description {
				max-width: 450px;
			}
		}

		.links {
			flex: 3;
			@include marginEnd(5rem);

			@media (max-width: 576px) {
				@include marginEnd(0rem);
			}

			> ul {
				display: flex;
				flex-wrap: wrap;

				> a,
				> li {
					width: 33%;
					text-transform: capitalize;
				}
			}
		}

		.follow-us,
		.massage-us {
			flex: 2;
		}

		.follow-us {
			display: none;
			&-items {
				border: 1px solid white;
				border-radius: 50%;
				width: 1.6rem;
				height: 1.6rem;
			}
		}
	}

	.copyrights {
		font-family: sans-serif;

		span {
			font-size: 12px;
		}

		a {
			font-size: 16px;
		}
	}

	&__image {
		z-index: -1;

		img {
			object-fit: fill;
		}

		@media (max-width: 992px) {
			bottom: calc(100% - $transparent-offset - 1px);

			@media (max-width: 576px) {
				bottom: calc(100% - $transparent-offset-small - 1px);
			}

			img {
				object-fit: cover;
			}
		}
	}
}

.footer__title {
	&--sub {
		font-size: 0.9rem;
	}
}
</style>
<style>
[dir="rtl"] .footer__image {
	transform: rotateY(180deg);
}

.top-description * {
	font-size: inherit;
	color: inherit;
	margin-bottom: 0;
	text-align: justify;
}
</style>