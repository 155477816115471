import * as types from "../mutations"

export default {
    namespaced: true,
    state: {
        journey: []
    },
    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/services', { params: { useOrder: true } }).then(res => {
                commit(types.JOURNEY_STORE, res.data)
            })
        }
    },
    mutations: {
        [types.JOURNEY_STORE](state, journey) {
            state.journey = journey
        }
    }
}