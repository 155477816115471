import * as types from "../mutations"

export default {
    namespaced: true,
    state: {
        states: []
    },
    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/states').then(res => {
                commit(types.STATES_STORE, res.data)
            })
        },
        fetchById({ commit }, { id }) {
            return window.axios.get(`/states/${id}`).then(res => res.data)
        }
    },
    mutations: {
        [types.STATES_STORE](state, states) {
            state.states = states
        }
    }
}