import * as types from "../mutations"

export default {
    namespaced: true,
    state: {
        about: [],
        values: []
    },

    actions: {
        fetchAll({ commit }) {
            return Promise.all([
                window.axios.get('/aboutus')
                    .then(res => {
                        commit(types.ABOUT_STORE, res.data)
                    }),
                window.axios.get('/company-values')
                    .then(res => {
                        commit(types.VALUES_STORE, res.data)
                    }),
            ])
        },
    },

    mutations: {
        [types.ABOUT_STORE](state, about) {
            state.about = about
        },
        [types.VALUES_STORE](state, values) {
            state.values = values
        }
    },

    getters: {
        mission: state => state.about.find(e => e.key === 'mission'),
        about: state => state.about.find(e => e.key === 'about'),
        vision: state => state.about.find(e => e.key === 'vision')
    }
}