<template>
	<div
		class="container-fluid"
		data-aos="fade-right"
		data-aos-anchor-placement="top-bottom"
		data-aos-duration="800"
	>
		<div class="background">
			<div class="container pb-0">
				<breadcrumb
					:links="[
						{ name: $t('breadcrumbs.home'), path: { name: 'home' } },
						{ name: $t('breadcrumbs.about') },
					]"
				></breadcrumb>
			</div>
			<loader v-if="loading"></loader>
			<div class="container pt-2" v-else>
				<div class="image about-image">
					<img src="@/assets/Homepage/2.png" alt="" />
				</div>
				<div class="image about-image">
					<img src="@/assets/Homepage/3.png" alt="" />
				</div>
				<section class="about">
					<h2 class="title--about" style="font-size: 3.5rem">
						{{ $t("about.mirta-title") }}
					</h2>
					<div class="about__paragraph" v-html="about?.value"></div>
				</section>
				<section class="about">
					<h2 class="title--about fs-1">{{ $t("about.world-title") }}</h2>
					<div class="about__paragraph" v-html="mission.value"></div>
				</section>
				<section class="about">
					<div class="about-grid">
						<div class="about-grid-item" style="grid-area: v0">
							<h3 class="title-">{{ values[0].title }}</h3>
							<div
								class="about-grid-item-description"
								v-html="values[0].content"
							></div>
						</div>
						<div class="about-grid-item" style="grid-area: v1">
							<h3 class="title-">{{ values[1].title }}</h3>
							<div
								class="about-grid-item-description"
								v-html="values[1].content"
							></div>
						</div>
						<div class="about-grid-item" style="grid-area: v2">
							<h3 class="title-">{{ values[2].title }}</h3>
							<div
								class="about-grid-item-description"
								v-html="values[2].content"
							></div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Loader from "@/components/Loader.vue";
import { mapGetters, mapState } from "vuex";
export default {
	data() {
		return {
			loading: false,
		};
	},
	computed: {
		...mapState({
			values: (state) => state.about.values,
		}),
		...mapGetters({
			about: "about/about",
			mission: "about/mission",
		}),
	},
	components: {
		Loader,
		Breadcrumb,
	},
};
</script>

<style scoped lang="scss">
.container-fluid {
	.background {
		background: var(--background-color-opacity);
		position: relative;
	}
	.about-image {
		position: absolute;
		z-index: -1;
		&:first-child {
			top: 5%;
		}
		&:nth-child(2) {
			bottom: 0;
		}
	}
}
.about {
	text-align: center;
	max-width: 80%;
	@media (max-width: 576px) {
		max-width: 100%;
	}
	margin: auto;
	margin-bottom: 3rem;
	&-grid {
		display: flex;
		@media (max-width: 768px) {
			flex-direction: column;
			align-items: center;
		}
		justify-content: center;
		margin: auto;
		&-item {
			max-width: 300px;
			margin-bottom: 1.5rem;
			&:nth-child(2) {
				margin-top: 100px;
				@media (max-width: 768px) {
					margin-top: 0;
				}
			}
			transition: 1s;
			&:hover {
				transform: scale(1.1);
				transition: 200ms;
			}
		}
	}
}
</style>
<style lang="scss">
.about-image {
	[lang="en"] &,
	[dir="ltr"] {
		&:first-child {
			right: 0;
		}
		&:nth-child(2) {
			left: -200px;
		}
	}
	[lang="ar"] &,
	[dir="rtl"] {
		&:first-child {
			left: 0;
		}
		&:nth-child(2) {
			right: 0;
		}
	}
}
</style>