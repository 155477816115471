<template>
	<div
		v-if="!products.length"
		class="text-center fs-3 text-uppercase fw-light text-muted my-3"
	>
		{{ $t("infos.select-filter") }}
	</div>
	<div class="products-grid" v-else>
		<product-card
			v-for="product in products.filter((e) => !e.isFeatured)"
			:key="product.id"
			:product="product"
		></product-card>
	</div>
</template>

<script>
import ProductCard from "./ProductCard.vue";
export default {
	components: {
		ProductCard,
	},
	props: ["products"],
};
</script>

<style scoped lang="scss">
.products-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(150px, 200px));

	gap: 2rem;
	margin: auto;
	@media (max-width: 768px) {
		justify-content: flex-start;
		grid-template-columns: 1fr;
		padding: 0 1rem;
	}
}
</style>