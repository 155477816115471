import Vue from "vue";
import VueI18n from "vue-i18n";
import ar from "./ar.json"
import en from "./en.json"

Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: 'en',
    messages: {
        ar: ar,
        en: en
    }
})

export default i18n;

export function changeI18nLanguage(l) {
    var html = document.querySelector('html')

    if (l === 'en') {
        i18n.locale = 'en';
        html.lang = 'en'
        html.dir = 'ltr'

        localStorage.setItem('mirta-language', JSON.stringify({ language: 'en' }))
    }
    if (l === 'ar') {

        i18n.locale = 'ar';
        html.lang = 'ar'
        html.dir = 'rtl'

        localStorage.setItem('mirta-language', JSON.stringify({ language: 'ar' }))
    }
}

if ('mirta-language' in localStorage) {
    i18n.locale = JSON.parse(localStorage['mirta-language']).language;
    const html = document.querySelector('html')
    html.lang = i18n.locale
    html.dir = i18n.locale === 'en' ? 'ltr' : 'rtl'
}