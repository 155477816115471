<template>
	<loader v-if="loading"></loader>
	<div
		class="home-container"
		v-else
		style="--first-title: #cd7c3a; --journey-color: #174b42"
	>
		<section class="section1 ps-5">
			<h2 class="title--home section1__title" style="color: var(--first-title)">
				{{ $t("home.titles.drink") }}
			</h2>
			<div
				class="image section1__image"
				data-aos="zoom-in"
				data-aos-anchor-placement="top-bottom"
				data-aos-offset="400"
			>
				<img src="@/assets/Homepage/mirta coffee.png" alt="" />
			</div>
			<div class="values">
				<div
					class="values-item values-item--first fix-postion-right"
					data-aos="fade-right"
					data-aos-anchor-placement="top-bottom"
					data-aos-offset="400"
					data-aos-anchor=".values"
				>
					<h3 class="b-c values-item__title fw-bold">{{ values[0].title }}</h3>
					<p class="values-item__title" v-html="values[0].content"></p>
				</div>
				<div
					class="values-item values-item--sec fix-postion-left"
					data-aos="fade-left"
					data-aos-anchor-placement="top-bottom"
					data-aos-offset="400"
					data-aos-anchor=".values"
				>
					<h3 class="b-c values-item__title fw-bold">{{ values[1].title }}</h3>
					<p class="values-item__title" v-html="values[1].content"></p>
				</div>
				<div
					class="values-item values-item--third fix-postion-left"
					data-aos="fade-right"
					data-aos-anchor-placement="top-bottom"
					data-aos-offset="400"
					data-aos-anchor=".values"
				>
					<h3 class="b-c values-item__title fw-bold">{{ values[2].title }}</h3>
					<p class="values-item__title" v-html="values[2].content"></p>
				</div>
			</div>
		</section>
		<section class="section2 d-lg-flex d-block">
			<h2 class="title--home section2__title m-c just-en">
				A World of
				<span class="en"
					>C<img
						src="@/assets/Homepage/Cup.png"
						alt=""
						ref="section2Image"
					/>ffee</span
				>
				Flavors
			</h2>
			<h2 class="title--home section2__title m-c just-ar">
				<span class="ar">
					<span> عــالـم من النكهات </span>
					<img src="@/assets/Homepage/Cup.png" alt="" ref="section2ImageAr" />
				</span>
			</h2>
			<div class="section2__contant">
				<div class="row mb-3">
					<div class="col-sm-8 col-12" v-html="mission.value"></div>
					<div class="section2__image col-4">
						<img
							src="@/assets/Homepage/2.png"
							alt=""
							class="fix-postion-left ms-3"
							data-aos="zoom-in"
							data-aos-anchor-placement="top-bottom"
							data-aos-offset="400"
						/>
					</div>
				</div>

				<div class="row mb-5 section2__products">
					<small-product-card
						:product="{
							image: require('@/assets/Collection page/Arabic_Coffee.png'),
							name: 'Arabic Coffee',
							nameAr: 'قهوة عربية',
						}"
						data-aos="fade-right"
						data-aos-anchor-placement="top-bottom"
						data-aos-offset="400"
						data-aos-anchor=".section2__products"
						class="col-sm-4 col-12 mb-sm-0 mt-3"
					></small-product-card>
					<small-product-card
						:product="{
							image: require('@/assets/Collection page/Mirta_Espresso.png'),
							name: 'Mirta Espresso',
							nameAr: 'اسبريسو ميرتا',
						}"
						data-aos="fade-right"
						data-aos-anchor-placement="top-bottom"
						data-aos-offset="400"
						data-aos-anchor=".section2__products"
						data-aos-delay="200"
						class="col-sm-4 col-12 mb-sm-0 mt-3"
					></small-product-card>
					<small-product-card
						:product="{
							image: require('@/assets/Collection page/Olabi_Coffee_Mix.png'),
							name: 'Olabi Coffee Mix',
							nameAr: 'خلطة قهوة العلبي',
						}"
						data-aos="fade-right"
						data-aos-anchor-placement="top-bottom"
						data-aos-offset="400"
						data-aos-anchor=".section2__products"
						data-aos-delay="400"
						class="col-sm-4 col-12 mb-sm-0 mt-3"
					></small-product-card>
				</div>
				<div
					class="button section2__button"
					@mouseenter="
						$refs.section2Image.classList.add('animate');
						$refs.section2ImageAr.classList.add('animate');
					"
					@mouseout="
						$refs.section2Image.classList.remove('animate');
						$refs.section2ImageAr.classList.remove('animate');
					"
					@click="seeCollections"
				>
					{{ $t("buttons.see-our") }}
				</div>
			</div>
		</section>
		<section class="section3">
			<div class="image section3__background">
				<img
					src="@/assets/Homepage/istockphoto-1136912418-612x612.png"
					alt=""
				/>
			</div>
			<div class="row">
				<div
					class="section3__image col-3 d-sm-block d-none"
					ref="section3Image"
					arabic
				>
					<img
						src="@/assets/Homepage/3.png"
						alt=""
						class="fix-postion-right"
						data-aos="zoom-out"
						data-aos-anchor-placement="top-bottom"
						data-aos-offset="400"
					/>
				</div>
				<div class="col-sm-9 col-12">
					<h2 class="title--home m-c">{{ $t("home.titles.mirta") }}</h2>
					<p class="mb-5" v-html="about.value"></p>
					<div
						class="button"
						@mouseenter="$refs.section3Image.classList.add('animate')"
						@mouseout="$refs.section3Image.classList.remove('animate')"
						@click="seeBranches"
					>
						{{ $t("buttons.visit") }}
					</div>
				</div>
			</div>
		</section>
		<section class="section4">
			<h2
				class="title--home section4__title"
				style="color: var(--journey-color)"
			>
				{{ $t("home.titles.journey") }}
			</h2>
			<div class="row align-items-center section4__contant">
				<div
					class="section4__image col-md-5 col-12 center-item"
					:class="{ wide: journeyIndex === 0 }"
					ref="journeyImage"
					data-aos="fade-left"
					data-aos-anchor-placement="top-bottom"
					data-aos-offset="400"
				>
					<img
						:src="journey[journeyIndex]?.primaryImage.url"
						:alt="journey[journeyIndex]?.title"
					/>
				</div>
				<div class="position-relative col-md-7 col-12">
					<div class="journey-slider" dir="ltr">
						<div
							class="journey-slider__container d-flex justify-content-around"
							ref="slider"
							:style="`right: ${100 * journeyIndex}%; width: ${
								100 * journey.length
							}%`"
						>
							<div
								class="journey-slider__item"
								:style="`width: ${100 / journey.length}%`"
								v-for="(image, i) in journey"
								:key="i"
							>
								<h3 class="section4__sub-title fw-bold">
									{{ image.title }}
								</h3>
								<div v-html="image.content"></div>

								<h3 class="section4__sub-title"></h3>
							</div>
						</div>
					</div>
					<div class="journey-slider__nav">
						<div class="nav--pref" @click="journeyIndex > 0 && journeyIndex--">
							<i
								class="fas"
								:class="isEnglish ? ' fa-angle-left' : ' fa-angle-right'"
							></i>
						</div>
						<div
							class="nav--next"
							@click="journeyIndex < journey.length - 1 && journeyIndex++"
						>
							<i
								class="fas"
								:class="isEnglish ? ' fa-angle-right' : ' fa-angle-left	'"
							></i>
						</div>
					</div>
				</div>
			</div>
			<div class="px-lg-5 px-0">
				<div class="journey-image-slider">
					<div class="journey-image-slider__container d-flex">
						<div
							class="journey-image-slider__item"
							v-for="(image, i) in journey"
							:key="i"
							@click="journeyIndex = i"
						>
							<div
								class="d-flex flex-column justify-content-center"
								:class="{ active: i === journeyIndex }"
							>
								<div class="image">
									<img :src="image.primaryImage.url" alt="image.title" />
								</div>
								<div class="text-center item-name">
									{{ image.title }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import SmallProductCard from "@/components/SmallProductCard.vue";
import Loader from "@/components/Loader.vue";
import { mapState, mapGetters } from "vuex";
import { openCollection, openBranches } from "@/variables";

export default {
	components: {
		SmallProductCard,
		Loader,
	},
	data() {
		return {
			journeyIndex: 0,

			touchX: null,

			loading: false,

			openCollection,
			openBranches,
		};
	},
	methods: {
		handleTouchStart(evt) {
			this.touchX = evt.touches[0].clientX;
		},
		handleTouchEnd(evt) {
			const newTouch = evt.changedTouches[0].clientX;
			if (newTouch > this.touchX + 50) {
				this.journeyIndex > 0 && this.journeyIndex--;
			}
			if (newTouch < this.touchX - 50) {
				this.journeyIndex < this.journey.length - 1 && this.journeyIndex++;
			}
		},
		seeCollections() {
			setTimeout(() => {
				window.scrollTo({ top: 0, behavior: "smooth" });
				this.openCollection.open = !this.openCollection.open;
			}, 100);
		},
		seeBranches() {
			setTimeout(() => {
				window.scrollTo({ top: 0, behavior: "smooth" });
				this.openBranches.open = !this.openBranches.open;
			}, 100);
		},
		init() {
			this.$refs.slider.addEventListener("touchstart", this.handleTouchStart);
			this.$refs.slider.addEventListener("touchend", this.handleTouchEnd);
		},
	},
	computed: {
		isEnglish() {
			return document.querySelector("html").lang === "en";
		},
		...mapState({
			journey: (state) => state.journey.journey,
			values: (state) => state.about.values,
		}),
		...mapGetters({
			about: "about/about",
			mission: "about/mission",
		}),
	},
	watch: {
		journeyIndex() {
			this.$refs.journeyImage.style.transform = "translate(50px ,0)";
			this.$refs.journeyImage.style.opacity = "0";
			this.$refs.journeyImage.style.transition = "0ms";
			setTimeout(() => {
				this.$refs.journeyImage.style.transform = "translate(0, 0)";
				this.$refs.journeyImage.style.opacity = "1";
				this.$refs.journeyImage.style.transition = "200ms ease";
			}, 150);
		},
	},
	mounted() {
		this.init();
	},
};
</script>

<style lang="scss">
@import "../mixins.scss";
.home-container {
	background-image: linear-gradient(#ded1c86d, #ded1c86d),
		url("@/assets/Homepage/background.png");
	background-size: 100%;
	background-repeat: no-repeat;
	background-size: cover;

	padding: 0 5% 200px;
	margin-bottom: -220px;
	overflow-x: hidden;

	section {
		&:first-child {
			padding-bottom: 5rem;
			margin-bottom: 5rem;
			@media (max-width: 768px) {
				padding-bottom: 0;
			}
		}
		&:not(:first-child) {
			margin-bottom: 6rem;
			@media (max-width: 768px) {
				margin-bottom: 5rem;
			}
		}
		position: relative;
	}
}

.section1 {
	@media (min-width: 992px) {
		display: flex;
	}

	&__title {
		width: 30vw;
		@media (max-width: 992px) {
			width: 50%;
		}
		@media (max-width: 768px) {
			width: 100%;
		}
	}
	&__image {
		padding: 80px 0;
		display: block;
		width: 20vw;
		@media (max-width: 768px) {
			width: 250px;
			padding: 20px 0;
			margin: auto;
		}
	}
	.values {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		&-item {
			&__title {
				margin-bottom: 0;
			}
			position: absolute;
			width: 300px;
			&--first {
				top: 8vw;
				@include marginEnd(8vw);
				@media (max-width: 992px) {
					@include right(50px !important);
				}
			}
			&--sec {
				top: 47%;
				@media (max-width: 992px) {
					@include right(100px !important);
				}
			}
			&--third {
				// bottom: 30px;
				@include twoValues(bottom, 30px, 70px);
				@include marginStart(55vw);
				@media (max-width: 992px) {
					bottom: -50px;
					@include right(150px !important);
				}
			}
			@media (max-width: 992px) {
				@include twoProperties(left, right, unset !important);
				margin: 0 !important;
			}
			&:hover {
				transform: scale(1.05) !important;
				transition: 200ms;
			}
		}
		@media (max-width: 768px) {
			position: static;
			&-item {
				position: static;
				width: 100%;
				margin-bottom: 1rem !important;
			}
		}
	}
}

.section2 {
	&__title {
		min-width: 250px;
		max-width: 380px;
		@media (max-width: 1200px) {
			min-width: 220px;
			max-width: 300px;
		}
		span.en {
			font-size: 1.5em;
			display: inline-block;
			width: max-content;
			img {
				font-size: 1em;
				height: 1em;
				vertical-align: baseline;
				margin-bottom: -0.3rem;
				transition: 400ms;
				&.animate {
					transform: scaleX(-1);
				}
			}
		}
		span.ar {
			display: flex;
			align-items: center;
			@media (max-width: 992px) {
				display: block;
				width: max-content;
			}
			@media (max-width: 576px) {
				width: 90vw;
			}
			span {
				width: 7rem;
				font-size: 3rem;
				@media (max-width: 1200px) {
					font-size: 2.3rem;
				}
			}
			img {
				font-size: 1em;
				height: 1.3em;
				vertical-align: baseline;
				margin-right: 0;
				@media (max-width: 992px) {
					margin-right: 0;
				}
				transition: 400ms;
				transform: scaleX(-1);
				&.animate {
					transform: scaleX(1);
				}
			}
		}
	}
	&__contant {
		flex: 1;
	}
	&__image {
		position: relative;
		@media (max-width: 576px) {
			z-index: -1;
		}
		img {
			position: absolute;
			width: 300px;
			height: auto;
			bottom: -50px;
			@media (max-width: 992px) {
				margin: 0;
				width: 250px;
			}
		}
	}
}

.section3 {
	&__image {
		position: relative;
		transition: 1s;
		&.animate {
			transform: scale(1.1);
		}
		img {
			width: 600px;
			position: absolute;
			top: -50px;
		}
		@include justArabic {
			transform: scaleX(-1);
			img {
				right: 0;
			}
		}
	}
	&__background {
		position: absolute;
		top: -200px;
		bottom: -200px;
		right: 0;
		left: 0;
		z-index: -1;
		@media (max-width: 992px) {
			right: -50%;
			left: -50%;
		}
	}
}

.section4 {
	&__image {
		height: 250px;
		@media (max-width: 768px) {
			height: 150px;
		}
		&.wide {
			img {
				transform: scale(1.1);
			}
		}
		img {
			height: 100%;
			width: 100%;
			object-fit: contain;
		}
	}
	&__contant {
		@media (min-width: 768px) {
			flex-direction: row-reverse;
		}
	}
}

.journey-slider {
	overflow: hidden;
	position: relative;
	&__container {
		position: relative;
		right: 0%;
		transition: 200ms;
	}
	&__item {
		@include justArabic {
			direction: rtl;
		}
	}
	&__nav {
		position: absolute;
		top: 45%;
		@include twoProperties(right, left, 0px);
		@include twoProperties(left, right, -15px);
		display: flex;
		justify-content: space-between;
		* {
			font-size: 1.3rem;
			cursor: pointer;
		}
		@media (max-width: 992px) {
			display: none;
		}
	}
	@include justArabic {
		text-align: right;
	}
}

.journey-image-slider {
	overflow: hidden;
	@media (max-width: 992px) {
		overflow-x: auto;
	}
	&__container {
		@media (max-width: 992px) {
			width: max-content;
		}
	}
	&__item {
		width: 18%;
		max-width: 250px;
		margin: 0 auto;
		@media (max-width: 992px) {
			width: 200px;
			margin-right: 20px;
		}
		> div {
			height: 100%;
			border-radius: 20px;
			padding: 1rem 0.5rem;
			cursor: pointer;
			transition: 200ms;
			@media (max-width: 992px) {
				box-shadow: 0 0 8px #0003 inset;
			}
			.image {
				height: 50%;
				flex: 1;
			}
			.item-name {
				max-width: 180px;
				margin: 0 auto;
				flex: 1;
			}
			&:hover {
				background: #0003;
				box-shadow: none;
			}
			&.active {
				background: #0003;
				box-shadow: none;
			}
		}
	}
}

.title--home {
	@include twoValues(font-size, 4rem, 3rem);
	font-family: titleFont, titleFontAr;
	@media (max-width: 1200px) {
		@include twoValues(font-size, 3.2rem, 2.3rem);
	}
}
</style>