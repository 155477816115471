import * as types from "../mutations"

export default {
    namespaced: true,

    state: {
        categories: [],
        branches: [],
        sets: [],

        contacts: [],
    },
    actions: {
        async fetchAll({ commit }) {
            await window.axios.get('/sets').then(res => {
                commit(types.SETS_STORE, res.data)
            })
            await window.axios.get('/categories').then(res => {
                commit(types.CATEGORIES_STORE, res.data)
            })
            await window.axios.get('/branches').then(res => {
                commit(types.BRANCHES_STORE, res.data)
                commit('initBranches')
            })
        },
        fetchContactsBySlug({ commit }, { slug }) {
            return window.axios.get(`/contacts/${slug}`).then(res => res.data)
        },
        fetchAllContacts({ commit }) {
            return window.axios.get(`/contacts`).then(res => {
                commit(types.CONTACTS_STORE, res.data)
            })
        }
    },
    mutations: {
        [types.BRANCHES_STORE](state, branches) {
            state.branches = branches
        },
        [types.CATEGORIES_STORE](state, categories) {
            state.categories = categories
        },
        [types.SETS_STORE](state, sets) {
            state.sets = sets
        },

        [types.CONTACTS_STORE](state, contacts) {
            state.contacts = contacts
        },

        initBranches(state) {
            for (let i = 0; i < state.sets.length; i++) {
                const s = state.sets[i];
                s.branches = []
                for (let j = 0; j < state.categories.length; j++) {
                    const b = state.categories[j]
                    if (b.setsIds.includes(s.id)) {
                        s.branches.push(b)
                    }
                }
            }
        }
    },
    getters: {
        getById: state => id => state.branches.find(e => e.id == id)
    }
}