<template>
	<div
		class="container"
		data-aos="fade-right"
		data-aos-anchor-placement="top-bottom"
	>
		<breadcrumb
			:links="[
				{ name: $t('breadcrumbs.home'), path: { name: 'home' } },
				{ name: $t('breadcrumbs.albums') },
			]"
		></breadcrumb>
		<loader v-if="loading"></loader>
		<div v-else>
			<div class="grid">
				<events-card
					v-for="event in events"
					:key="event.id"
					:event="event"
				></events-card>
			</div>
			<div
				class="text-center fs-3 text-uppercase fw-light text-muted mt-5"
				v-if="!events.length"
			>
				{{ $t("albums.no-data") }}
			</div>
		</div>
	</div>
</template>

<script>
import EventsCard from "@/components/EventsCard.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import { mapState } from "vuex";
import Loader from "@/components/Loader.vue";
export default {
	data() {
		return {
			loading: true,
		};
	},
	computed: {
		...mapState({
			events: (state) => state.events.events,
		}),
	},
	mounted() {
		this.$store.dispatch("events/fetchAll").then(() => {
			this.loading = false;
		});
	},
	watch: {
		"$i18n.locale"() {
			this.loading = true;
			this.$store.dispatch("events/fetchAll").then(() => {
				this.loading = false;
			});
		},
	},
	components: {
		EventsCard,
		Breadcrumb,
		Loader,
	},
};
</script>

<style scoped lang="scss">
.grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 300px));
	justify-content: center;

	gap: 20px;
}
</style>
