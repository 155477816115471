<template>
	<div
		class="container"
		data-aos="fade-right"
		data-aos-anchor-placement="top-bottom"
	>
		<breadcrumb
			:links="[
				{ name: $t('breadcrumbs.home'), path: { name: 'home' } },
				{ name: $t('breadcrumbs.albums'), path: { name: 'all-events' } },
				{ name: album.title },
			]"
		></breadcrumb>
		<loader v-if="loading"></loader>
		<div v-else>
			<vue-easy-lightbox
				escDisabled
				moveDisabled
				:visible="visible"
				:imgs="album.images.map((e) => e.url)"
				:index="index"
				@hide="handleHide"
			></vue-easy-lightbox>
			<h1 class="title--event text-center mt-4 mb-2">
				{{ album.title }}
			</h1>
			<div class="grid">
				<div class="p-2" v-for="(image, i) in album.images" :key="i">
					<div class="image" @click="showMultiple(i)">
						<img :src="image.url" :alt="`image ${i}`" />
					</div>
					<div class="text text-center fs-5">
						{{ image.caption }}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import Loader from "@/components/Loader.vue";
import VueEasyLightbox from "vue-easy-lightbox";
// import "vue-easy-lightbox/external-css/vue-easy-lightbox.css";

export default {
	data() {
		return {
			album: {},

			loading: true,

			//  lightbox variables
			visible: false,
			index: 0,
		};
	},
	methods: {
		showMultiple(i) {
			this.index = i;
			this.show();
		},
		show() {
			this.visible = true;
		},
		handleHide() {
			this.visible = false;
		},
	},
	watch: {
		"$i18n.locale"() {
			this.loading = true;
			this.$store
				.dispatch("events/fetchById", { id: this.$route.params.id })
				.then((res) => {
					this.album = res;
				})
				.then(() => {
					this.loading = false;
				});
		},
	},
	mounted() {
		this.$store
			.dispatch("events/fetchById", { id: this.$route.params.id })
			.then((res) => {
				this.album = res;
			})
			.then(() => {
				this.loading = false;
			});

		setTimeout(() => {
			this.$el.style.transform = "unset";
		}, 1000);
	},
	components: {
		Breadcrumb,
		Loader,
		VueEasyLightbox,
	},
};
</script>

<style scoped lang="scss">
@import "../../mixins.scss";

.grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(200px, 300px));
	justify-content: center;

	gap: 20px;

	.image {
		border: 1px solid #ccc;
		border-radius: 20px;
		height: 250px;
	}
}

.title--event {
	@include twoValues(font-size, 4rem, 3rem);
	text-transform: capitalize;
}
</style>
