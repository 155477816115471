<template>
	<div class="nav-container" ref="nav">
		<nav
			class="nav"
			style="--nav-color: white; --nav-bg-color: var(--main-color)"
		>
			<div class="brand" style="--nav-brand-width: 70px">
				<router-link :to="{ name: 'home' }">
					<div class="image brand__image">
						<img src="@/assets/Homepage/logo.png" alt="logo" />
						<div class="image brand__image--overflow fix-posetion-left">
							<img src="@/assets/Homepage/year.png" alt="year" />
						</div>
					</div>
				</router-link>
			</div>

			<a
				href="https://mirta.net/"
				target="mirta_co"
				class="other-site d-flex align-items-center"
			>
				<div class="image"><img src="@/assets/boxes.png" alt="" /></div>
				{{ $t("other-site") }}
			</a>
			<div class="hamburger">
				<i
					class="fa fa-bars px-4 py-3"
					data-bs-toggle="collapse"
					data-bs-target="#links"
				></i>
			</div>

			<div class="links collapse" id="links" ref="links">
				<ul class="links__list">
					<router-link :to="{ name: 'home' }">
						<li class="links__list__item">{{ $t("navbar.home") }}</li>
					</router-link>

					<li
						class="links__list__item"
						@click.self="
							collectionListOpen = false;
							branchesListOpen = !branchesListOpen;
						"
						ref="branches"
					>
						<span
							class="has-dropdown-list"
							no-hover
							:class="{ active: branchesListOpen }"
							@click.self="
								collectionListOpen = false;
								branchesListOpen = !branchesListOpen;
							"
						>
							{{ $t("navbar.branches") }}
						</span>
						<div
							class="dropdown-list shadow"
							id="branches-list"
							:class="{ show: branchesListOpen }"
						>
							<ul class="dropdown-list--list p-3">
								<router-link
									:to="{ name: 'branche', params: { id: branche.slug } }"
									v-for="(branche, i) in sets"
									:key="i"
								>
									<li class="dropdown-list--item m-1">
										{{ branche.name }}
									</li>
								</router-link>
							</ul>
						</div>
					</li>
					<li
						class="links__list__item"
						@click.self="
							branchesListOpen = false;
							collectionListOpen = !collectionListOpen;
						"
						ref="collections"
					>
						<span
							class="has-dropdown-list"
							no-hover
							:class="{ active: collectionListOpen }"
							@click.self="
								branchesListOpen = false;
								collectionListOpen = !collectionListOpen;
							"
						>
							{{ $t("navbar.our-collection") }}
						</span>
						<div
							class="dropdown-list shadow"
							id="collection-list"
							:class="{ show: collectionListOpen, 'show-filters': openFilters }"
						>
							<loader v-if="loading"></loader>
							<div class="p-3" v-else>
								<div class="cities mb-3">
									<div
										class="cities-item me-2"
										v-for="branche in sets"
										:key="branche.id"
										:class="{ active: branche.id === activeBranche }"
										@click="
											activeBranche = branche.id;
											activeState = branche.branches[0].id;
											openFilters = false;
										"
									>
										{{ branche.name }}
									</div>
									<div
										class="state-close for-mobile"
										@click="openFilters = false"
									>
										<i class="fas fa-times touch"></i>
									</div>
								</div>
								<!-- <div class="state-name for-mobile">
									{{ states.find((e) => e.id === activeState)?.name }}
								</div> -->
								<div class="list-body">
									<div class="city-branches me-1 col-4">
										<div
											class="city-branches-item"
											v-for="state in sets.find((e) => e.id == activeBranche)
												?.branches"
											:key="state.id"
											:class="{ active: state.id === activeState }"
											@click="
												activeState = state.id;
												openFilters = true;
											"
										>
											{{ state.name }}
										</div>
									</div>
									<div class="col-8 branche-collection">
										<div class="px-3 py-1">
											<div
												class="branche-collection-item"
												v-for="(filter, i) in filtersByState"
												:key="i"
											>
												<router-link
													:to="{
														name: 'all-products',
														params: {
															id: activeState,
														},
														query: { filter: filter.id },
													}"
												>
													{{ filter.name }}
												</router-link>
											</div>
											<div class="branche-collection-item">
												<router-link
													:to="{
														name: 'all-products',
														params: {
															id: activeState,
														},
														query: { filter: -1 },
													}"
												>
													{{ $t("bulk") }}
												</router-link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
					<router-link :to="{ name: 'all-events' }">
						<li class="links__list__item">{{ $t("navbar.albums") }}</li>
					</router-link>
					<router-link :to="{ name: 'about' }">
						<li class="links__list__item">{{ $t("navbar.about") }}</li>
					</router-link>
					<router-link :to="{ name: 'contact' }">
						<li class="links__list__item border">
							{{ $t("navbar.contact-us") }}
						</li>
					</router-link>
					<li
						class="links__list__item"
						@click.self="langListOpen = !langListOpen"
						ref="lang"
					>
						<i
							class="fa fa-globe"
							@click.self="langListOpen = !langListOpen"
						></i>

						<div
							class="dropdown-list shadow"
							id="lang-list"
							:class="{ show: langListOpen }"
						>
							<ul class="dropdown-list--list p-3">
								<li class="dropdown-list--item m-1" @click="changeLang('en')">
									English
								</li>
								<li class="dropdown-list--item m-1" @click="changeLang('ar')">
									عربي
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</div>
		</nav>
		<header
			class="header"
			@click="
				branchesListOpen = false;
				collectionListOpen = false;
			"
		>
			<div
				class="image header__image fill-width fill-height"
				ref="header_image"
			>
				<img
					:src="slider[sliderImageIndex % slider.length]?.image"
					alt=""
					:key="sliderImageIndex"
				/>
				<div class="header-clip-image">
					<img src="@/assets/Homepage/clip.png" alt="" />
				</div>
			</div>
			<div class="slider">
				<hooper
					class="hooper"
					:infiniteScroll="true"
					:mouseDrag="false"
					:wheelControl="false"
					:autoPlay="true"
					:playSpeed="4000"
					:rtl="!isEnglish"
					ref="carousel"
					@slide="changeSliderImage"
				>
					<slide v-for="slide in slider" :key="slide.id">
						<h1 class="slider-header-item" v-html="slide.description"></h1>
					</slide>
				</hooper>
			</div>
		</header>
		<div class="nav-image-overflow image">
			<img
				src="../assets/Homepage/clip.png"
				alt=""
				style="filter: brightness(99%)"
			/>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex";
import Loader from "@/components/Loader.vue";
import { openCollection, openBranches } from "@/variables";
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";
import { changeI18nLanguage } from "@/lang";

export default {
	data() {
		return {
			branchesListOpen: false,
			collectionListOpen: false,
			langListOpen: false,

			openFilters: false,

			activeBranche: 0,
			activeState: 0,

			loading: false,

			openCollection,
			openBranches,

			sliderImageIndex: 0,
		};
	},
	watch: {
		$route() {
			if (this.$route.name === "home") {
				this.$refs.nav.classList.add("home");
				this.$refs["header_image"].classList.add("header-image-enter");
				setTimeout(() => {
					this.$refs["header_image"].classList.remove("header-image-enter");
				}, 700);
			} else {
				this.$refs["header_image"].classList.remove("header-image-enter");
				this.$refs.nav.classList.remove("home");
			}
			this.$refs.links.classList.remove("show");
			this.branchesListOpen = false;
			this.collectionListOpen = false;
		},
		"openCollection.open"() {
			this.collectionListOpen = true;
			this.$refs.links.classList.add("show");
		},
		"openBranches.open"() {
			this.branchesListOpen = true;
			this.$refs.links.classList.add("show");
		},
	},
	computed: {
		isEnglish() {
			return document.querySelector("html").lang === "en";
		},
		filtersByState() {
			if (!this.activeState) return [];
			const categoriesProduct = this.categories.find(
				(e) => e.id === this.activeState
			).categoriesProduct;
			const notBulkProducts = categoriesProduct.filter((e) => !e.isFeatured);
			const filterIds = new Set();
			notBulkProducts.forEach((e) =>
				e.filterIds.forEach((c) => filterIds.add(c))
			);

			return this.filters.filter((e) => filterIds.has(e.id));
		},
		...mapState({
			sets: (state) => state.branches.sets,
			categories: (state) => state.branches.categories,
			branches: (state) => state.branches.branches,
			filters: (state) => state.filters.filters,
			slider: (state) => state.slider.slider,
		}),
	},
	methods: {
		sliderNext() {
			this.$refs.carousel.slideNext();
		},
		sliderPrev() {
			this.$refs.carousel.slidePrev();
		},
		changeLang(l) {
			changeI18nLanguage(l);
		},
		changeSliderImage(payload) {
			this.sliderImageIndex = payload.currentSlide;
			this.$refs["header_image"].classList.add("in");
			setTimeout(() => {
				this.$refs["header_image"].classList.remove("in");
			}, 500);
		},
	},
	mounted() {
		this.activeBranche = this.sets[0].id;
		this.activeState = this.sets[0].branches[0].id;

		// .then(() => {
		// 	this.sliderLoading = false;
		// });

		if (this.$route.name === "home") {
			this.$refs.nav.classList.add("home");
		}

		window.onclick = (event) => {
			if (!this.$refs.collections.contains(event.target)) {
				this.collectionListOpen = false;
			}
			if (!this.$refs.branches.contains(event.target)) {
				this.branchesListOpen = false;
			}
			if (!this.$refs.lang.contains(event.target)) {
				this.langListOpen = false;
			}
		};
	},
	components: {
		Loader,
		Hooper,
		Slide,
	},
};
</script>

<style scoped lang="scss">
@import "../mixins.scss";
.nav-image-overflow {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	transform: rotateY(180deg);
	z-index: 10;
	@media (min-width: 992px) {
		height: 80px;
	}
	height: 100px;
	@media (max-width: 768px) {
		left: -10%;
		right: -10%;
	}
	@media (max-width: 576px) {
		left: -25%;
		right: -25%;
	}
	img {
		object-fit: fill;
	}
}
.home .nav-image-overflow {
	display: none;
}

.header-clip-image {
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 10;
	@media (max-width: 768px) {
		right: -30%;
		left: -30%;
	}
	@media (max-width: 576px) {
		right: -50%;
		left: -50%;
	}
}
.in {
	animation: sliderIn 500ms ease-in forwards;
}
@keyframes sliderIn {
	from {
		filter: blur(15px);
	}
	to {
		filter: blur(0px);
	}
}
.header-image-enter {
	animation: headerImageEnter 0.7s forwards;
}
@keyframes headerImageEnter {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.nav-container {
	padding: 0 var(--window-padding);
	// background-image: url("@/assets/Internal_nav.png");
	background: var(--forth-color);
	&.home {
		background: linear-gradient(to bottom, #000c, #0000 74%);
		background-size: 100%;
		overflow-x: hidden;
	}
	background-size: 100% 200px;
	@media (max-width: 992px) {
		padding: 20px var(--window-padding);
		background-size: auto 100%;
	}
	@include twoValues(background-position, top left, 90% right);
	position: relative;
}

.nav {
	* {
		color: white;
	}
	padding-bottom: 70px;
	.brand {
		&__image {
			position: relative;
			margin-right: auto;
			&--overflow {
				width: 3rem;
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translate(-50%, 100%);
			}
		}
	}
	.links {
		&__list {
			@media (max-width: 992px) and (min-width: 768px) {
				padding-bottom: 4rem;
			}
			&__item {
				padding: 3rem 0 2rem;
				border-top: 5px solid transparent;
				@media (max-width: 1200px) {
					font-size: 0.85rem;
				}
				@media (max-width: 992px) {
					width: 100%;
					padding: 1rem 0;
				}
				@include touch-remove-hov {
					&:hover {
						color: var(--sec-color);
						span {
							color: var(--sec-color);
						}
					}
				}
				&.border {
					border-radius: 2rem;
				}
			}
		}
	}
}
.other-site {
	padding: 0.6rem 1.2rem;
	border-radius: 3rem;
	background: #ed1c24;
	color: white;
	text-transform: uppercase;
	font-size: 0.9rem;
	margin-top: 25px;
	margin-right: auto;
	margin-left: 2rem;
	font-weight: bold;
	@media (max-width: 1300px) {
		font-size: 0.7rem;
	}
	@media (max-width: 1260px) {
		width: min-content;
	}
	@media (max-width: 1020px) {
		font-size: 0.5rem;
	}
	@media (max-width: 992px) {
		width: max-content;
		margin-top: 0;
	}
	@media (max-width: 410px) {
		width: min-content;
	}

	.image {
		width: 1.2rem;
		filter: invert(1);
		margin: 0 5px;
		@media (max-width: 1020px) {
			width: 1.1rem;
		}
		@media (max-width: 992px) {
			width: 1rem;
		}
	}
	transition: 300ms;
	&:hover {
		transform: translateY(-5px);
		box-shadow: 0 0 10px #0006;
	}
}
.links__list__item:not(.border) {
	&:after {
		content: "";
		height: 5px;
		background: var(--sec-color);
		position: absolute;
	}
	&:hover::after {
		@include touch-remove-hov {
			animation: navbarHover 0.2s forwards ease-out;
		}
	}
}
@keyframes navbarHover {
	from {
		right: 30%;
		left: 30%;
		top: 30%;
	}
	to {
		right: 0;
		left: 0;
		top: -5px;
	}
}
@media (min-width: 992px) {
	.links.collapse:not(.show) {
		display: block;
	}
}
.dropdown-list {
	background: #ddd;
	border-radius: 20px;
	color: var(--font-color);
	z-index: 20;
	* {
		color: var(--font-color);
		font-size: 0.85rem;
	}
	&#collection-list {
		width: 600px;
		@include twoValues(left, -100%, -550%);
		@media (max-width: 768px) {
			width: 100%;
		}
		cursor: default;
		.cities {
			display: flex;
			&-item {
				border-bottom: 3px solid transparent;
				cursor: pointer;
				&.active {
					color: var(--sec-color);
					border-bottom: 3px solid var(--sec-color);
				}
			}
		}
		.list-body {
			display: flex;
			.city-branches {
				flex: 2;
				&-item {
					padding: 0.4rem;
					@include paddingEnd(1rem);
					cursor: pointer;
					&.active {
						background: white;
						border-radius: 8px;
						position: relative;
						&::after {
							font-family: "Font Awesome 5 Free";
							font-size: 16px;
							font-weight: 900;
							position: absolute;
							top: 50%;
							transform: translateY(-50%);

							@include twoValues(content, "\f105", "\f104");
							@include twoProperties(right, left, 5px);
						}
					}
				}
			}
			.branche-collection {
				flex: 5;
				min-height: 170px;
				@media (max-width: 768px) {
					flex: 2;
				}
				> div {
					display: flex;
					flex-wrap: wrap;
				}
				background: white;
				border-radius: 10px;
				&-item {
					width: 50%;
					margin-bottom: 0.5rem;
					line-height: 1.2rem;
					text-align: start;
					a {
						cursor: pointer;
					}
					a:hover {
						color: var(--sec-color);
					}
				}
			}
		}
	}
	&#branches-list {
		width: max-content;
		margin: auto;
		.dropdown-list--item {
			padding: 0.3rem;
			&:hover {
				color: var(--sec-color);
			}
		}
	}
	&#lang-list {
		@include right(0);
		.dropdown-list--item {
			padding: 7px 0;
		}
		.dropdown-list--item:hover {
			color: var(--sec-color);
		}
	}
	@media (max-width: 992px) {
		&#lang-list,
		&#branches-list {
			background: transparent;
			box-shadow: none !important;
			* {
				color: white;
			}
		}
	}
}

.header {
	padding-bottom: 0;
	max-height: 0;
	visibility: hidden;

	overflow: hidden;
	// transition: 300ms ease-in-out;
	&__image {
		z-index: -1;
		img {
			object-fit: cover;
			@media (max-width: 992px) {
				object-fit: cover;
			}
		}
	}
	.slider {
		max-width: 550px;
		position: relative;
		margin: 0 20px;
		min-height: 10rem;
		.hooper {
			height: auto;
		}
		.slider-nav {
			position: absolute;
			top: 50%;
			right: -20px;
			left: -20px;

			display: flex;
			justify-content: space-between;
			* {
				color: #0003;
				cursor: pointer;
				font-size: 1.2rem;
				&:hover {
					color: black;
				}
			}
		}
	}
}
.home .header {
	padding-bottom: 28vw;
	@media (max-width: 1200px) {
		padding-bottom: 22vw;
	}
	@media (max-width: 576px) {
		padding-bottom: 40vw;
	}
	max-height: 800px;
	visibility: visible;
}
@media (max-width: 992px) {
	#collection-list {
		max-width: 500px;
		margin: auto;
	}
	.branche-collection,
	.city-branches,
	.state-name {
		overflow: hidden;
		transition: all 200ms linear;
		max-width: 500px;
		width: 100% !important;
		flex: unset !important;
	}
	.state-name {
		max-height: 50px;
		margin-bottom: 0.5rem;
	}
	.state-close {
		@include marginStart(auto);
		visibility: visible;
		transition: 200ms;
	}
	#collection-list:not(.show-filters) {
		.state-name {
			max-height: 0;
		}
		.branche-collection {
			max-width: 0;
		}
		.state-close {
			visibility: hidden;
		}
	}
	#collection-list.show-filters {
		.city-branches {
			max-width: 0;
		}
	}
}
</style>
<style lang="scss">
[dir="rtl"] .header__image {
	transform: scaleX(-1);
}
[dir="rtl"] .nav {
	flex-direction: row-reverse;
}
.slider-header-item,
.slider-header-item * {
	font-size: 4.5rem;
	line-height: 5rem;
	color: #eaba6e;
	font-family: titleFont, titleFontAr;
	text-shadow: 0 0 10px #0006;
	@media (max-width: 992px) {
		font-size: 4rem;
		line-height: 4.5rem;
	}
	@media (max-width: 576px) {
		font-size: 3.3rem;
		line-height: 3.5rem;
	}
}
</style>