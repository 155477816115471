import Vue from 'vue'
import Vuex from 'vuex'
import products from "./modules/products"
import journey from "./modules/journey"
import filters from "./modules/filters"
import branches from "./modules/branches"
import states from "./modules/states"
import slider from "./modules/slider"
import feedback from "./modules/feedback"
import about from "./modules/about"
import events from "./modules/events"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    asd: []
  },
  modules: {
    products,
    journey,
    filters,
    branches,
    states,
    slider,
    feedback,
    about,
    events
  }
})
